<script>
    import { onMount, onDestroy } from "svelte";

    export let from;
    export let placeholder = "...";
    let refreshTime = null;
    let _refreshTimeInterval = null;

    onMount(() => {
        _refreshTimeInterval && clearInterval(_refreshTimeInterval);

        _refreshTimeInterval = setInterval(() => {
            if (!from) {
                return;
            }

            const H = 1000 * 60 * 60;
            const M = 1000 * 60;
            const S = 1000;

            const diff = Date.now() - from ?? Date.now();
            let h = 0,
                m = 0,
                s = 0,
                ms = diff;

            ms -= (h = Math.floor(ms / H)) * H;
            ms -= (m = Math.floor(ms / M)) * M;
            ms -= (s = Math.floor(ms / S)) * S;
            s = diff < 1000 ? s + Math.floor(ms / 100) / 10 : s;

            refreshTime = [
                //
                h > 0 ? `${h}h` : null,
                m > 0 || h > 0 ? `${m}m` : null,
                h < 1 ? `${s}s` : null,
            ]
                .filter(v => v)
                .join(" ");
        }, 1000);
    });

    onDestroy(() => {
        _refreshTimeInterval && clearInterval(_refreshTimeInterval);
    });
</script>

{refreshTime || placeholder}
