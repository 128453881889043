<script>
    import { useLocation, useNavigate } from "svelte-navigator";

    import * as api from "../api";
    import { token } from "../store";
    import ROUTES from "../routes";

    let location = useLocation();
    let navigate = useNavigate();

    // .menu style attribute
    let style = [];
    $: {
        // Clean
        style = [];

        // Assign
        if (ROUTES[$location.pathname]?.bgColor) {
            style = [...style, `background-color: ${ROUTES[$location.pathname]?.bgColor}`];
        }

        // Join
        style = style.join("; ");
    }

    function goto(newRoute = "") {
        // $location.pathname = newRoute;
        navigate(newRoute);
    }

    function onLogoutClick() {
        api.Logout().then(() => ($token = null));
    }
</script>

<div class="menu" {style}>
    <div class="menu-left">
        {#each Object.entries(ROUTES) as [key, route]}
            <button
                class="link"
                class:active={key === $location.pathname || route.alias?.includes($location.pathname)}
                on:click={() => goto(key)}
            >
                {route?.title || "???"}
            </button>
        {/each}
    </div>

    <div class="menu-right">
        <button class="link" on:click={onLogoutClick}>Logout</button>
    </div>
</div>

<style>
    .menu {
        position: sticky;
        top: 0;
        z-index: 1024;

        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr max-content;
        grid-gap: 8px;
        align-items: center;

        padding: 20px 16px;
        background-color: #1e90ff;
        box-shadow: 0 0 10px #999999;

        transition: all 500ms ease;
    }

    .link {
        padding: 8px 10px;
        border-radius: 0;
        background-color: unset;
        color: white;
        text-decoration: underline;
        cursor: pointer;

        transition: all 300ms ease;
    }

    .link:hover {
        text-decoration: underline;
    }

    .link.active {
        pointer-events: none;
        text-decoration-color: transparent;
        background-color: #ffffff;
        color: #444444;
    }
</style>
