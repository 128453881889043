<script>
    import { onMount } from "svelte";
    import * as api from "../api";
    import { token } from "../store";

    let fieldError = {};
    let allow = false;
    let request = false;

    let domLogin;

    onMount(() => domLogin.focus());

    function onFormChange() {
        let login = this.elements.namedItem("login").value;
        let password = this.elements.namedItem("password").value;

        allow = login.trim() && password.trim();
    }

    function onFormSubmit(event) {
        event.preventDefault();

        fieldError = { ...fieldError, response: null };
        request = true;
        api.Login({
            login: this.elements.namedItem("login").value,
            password: this.elements.namedItem("password").value,
        })
            .then(res => {
                if (res?.e) {
                    fieldError = { ...fieldError, response: res.e };
                    return;
                }

                if (res?.name && res?.value) {
                    $token = { name: res.name, value: res.value };
                } else {
                    $token = null;
                }
            })
            .finally(() => (request = false));
    }

    function onLoginInput() {
        this.value = this.value.toLowerCase();
    }
</script>

<div class="login">
    <form
        action="#"
        method="POST"
        on:input={onFormChange}
        on:change={onFormChange}
        on:submit={onFormSubmit}
        disabled={!allow}
    >
        <div class="header">
            <!-- svelte-ignore missing-declaration -->
            <h2>{Config.APP_TITLE}</h2>
        </div>

        <label>
            <!-- svelte-ignore a11y-autofocus -->
            <input
                bind:this={domLogin}
                type="text"
                name="login"
                placeholder="Login"
                on:input={onLoginInput}
                disabled={request}
                autofocus
            />
        </label>

        <label>
            <input type="password" name="password" placeholder="Password" disabled={request} />
        </label>

        <div>
            <button type="submit" disabled={request || !allow}> Login </button>
        </div>

        {#if fieldError["response"]}
            <div class="input-error text-center">
                {fieldError["response"]}
            </div>
        {/if}
    </form>
</div>

<style>
    .login {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f9f9f9;

        display: grid;
        justify-content: center;
        align-items: center;
    }

    form {
        width: 400px;
        padding: 20px;

        display: grid;
        grid-gap: 32px;

        background-color: #ffffff;
        box-shadow: 0 0 5px #cccccc;
    }

    h2 {
        text-align: center;
    }

    button {
        width: 100%;
    }

    @media (max-width: 719.95px) {
        form {
            width: 100vw;
        }
    }
</style>
