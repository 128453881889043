<script>
    export let show = true;
    export let up = true;
</script>

{#if show}
    <!-- svelte-ignore a11y-missing-attribute -->
    <img class:up src="/assets/sort-arrow.svg" />
{/if}

<style>
    img {
        display: inline-block;
        height: 16px;
        transform-origin: center;
        vertical-align: middle;
        line-height: 0;

        transform: rotateX(-180deg);
        transition: all 300ms ease;
    }

    .up {
        transform: rotateX(0deg);
    }
</style>
