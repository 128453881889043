<script>
    import APIAccessForm from "./APIAccessForm.svelte";
    import APIList from "./APIList.svelte";
    import RefreshTime from "./RefreshTime.svelte";

    let adding = JSON.parse(window.sessionStorage.getItem("addresses-adding") ?? "false");
    $: window.sessionStorage.setItem("addresses-adding", JSON.stringify(adding));

    let request = false;
    let refresh = Date.now();
    let lastLoad = null;

    function onAddingClick() {
        adding = !adding;
    }

    function onAddingChange(newAdding) {
        adding = newAdding;
    }

    function onAdded() {
        refresh = Date.now();
    }

    function onRefreshClick() {
        refresh = Date.now();
    }

    function onRefreshStart() {
        request = true;
    }

    function onRefreshEnd() {
        request = false;
        lastLoad = Date.now();
    }
</script>

<div class="container">
    <div class="grid-box-1 actions-row">
        <button type="button" on:click={onAddingClick} disabled={adding}>Add API access</button>

        <button on:click={() => onRefreshClick()}>
            Refresh

            {#if request}
                (...)
            {:else}
                (<RefreshTime from={lastLoad} placeholder="..." />)
            {/if}
        </button>
    </div>

    <APIAccessForm onHeaderCloseButtonClick={() => onAddingChange(false)} {adding} {onAdded} />
    <APIList {refresh} {onRefreshStart} {onRefreshEnd} />
</div>

<style>
    .container {
        display: grid;
        grid-gap: 10px;
    }
</style>
