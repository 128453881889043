function rand(a = 0, b = 1) {
    return Math.round(Math.random() * (b - a) + a);
}

function randOf(array = []) {
    return array[rand(0, array.length - 1)];
}

if (typeof module !== "undefined" && module.exports) {
    module.exports = { rand, randOf };
}

if (typeof define === "function" && define.amd) {
    define([], function () {
        return { rand, randOf };
    });
}
