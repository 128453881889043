<script>
    export let job = {};
</script>

{#if !job.lock}
    <div class="job-stages">
        <div class="job-stage" class:job-ok={job.okDig === true} class:job-wait={job.okDig === null} />
        <div class="job-stage" class:job-ok={job.okCert === true} class:job-wait={job.okCert === null} />
        <div class="job-stage" class:job-ok={job.okTpl === true} class:job-wait={job.okTpl === null} />
        <div class="job-stage" class:job-ok={job.okConf === true} class:job-wait={job.okConf === null} />
    </div>
{:else}
    &mdash;
{/if}

<style>
    .job-stages {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
    }

    .job-stage {
        width: 12px;
        height: 12px;
        border: 1px solid #444444;
        border-right-width: 0;
        background-color: tomato;
    }

    .job-stage:last-child {
        border-right-width: 1px;
    }

    .job-ok {
        background-color: yellowgreen;
    }

    .job-wait {
        background-color: #f2f2f2;
    }
</style>
