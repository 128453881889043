<script>
    import * as api from "../api/index";
    import { rand } from "../../lib/rand";

    const addressPlaceholders = [
        //
        "127.0.0.1",
        "10.10.10.1",
        "192.168.0.1",
        ...new Array(12).fill(null).map(() =>
            [
                //
                rand(1, 255),
                rand(0, 255),
                rand(0, 255),
                rand(1, 255),
            ].join(".")
        ),
    ];

    export let adding = false;
    export let editing = false;
    export let onHeaderCloseButtonClick = null;
    export let showHeaderCloseButton = !!onHeaderCloseButtonClick;
    export let onFooterCloseButtonClick = null;
    export let showFooterCloseButton = !!onFooterCloseButtonClick;
    export let onAdded = () => {};
    export let onEdited = () => {};

    export let name = "";

    export let permit = [""];
    $: permit.length === 0 && (permit = [""]);

    export let variables = {};

    export let nginx = `
# Example of PGBLDR.com

%TPL:80_TO_443%

server {
    access_log /var/log/nginx/%SERVER_NAME%.log;
    error_log /var/log/nginx/%SERVER_NAME%.err;

    listen 443 ssl;
    %TPL:SSL%
    %TPL:GZIP%

    server_name %SERVER_NAME%;
    server_name_in_redirect on;

    try_files $uri $uri/ @proxy;

    location ~* \.(js|webp|png|jpg|svg|ico|css|ttf)$ {
        expires 8m;
        add_header Cache-Control private;
        include /etc/nginx/proxy_params_mod;
        proxy_pass http://%SERVER_ADDR%;
    }

    location @proxy {
        %TPL:PROXY_PARAMS%
        proxy_pass http://%SERVER_ADDR%;
    }
}
`.trim();

    let startValue = JSON.stringify({ name, permit, nginx, variables });

    let allow = false;

    $: {
        name;
        permit;
        nginx;
        variables;
        startValue;
        checkAllow();
    }

    function onFormSubmit(event) {
        event.preventDefault();

        const action = editing ? api.ApiAccessUpd : api.ApiAccessAdd;

        action(name, permit, nginx, variables).then(res => {
            if (!res?.ok) {
                // res.e to array & map to alert
                const es = (res?.es || (res?.e && [{ e: res?.e }]))
                    .map(({ e }, i) => `${i + 1}. ${e}`)
                    .join("\n")
                    .trim();

                alert(es || "Unknown error");
                return;
            }

            if (adding) {
                name = "";
                permit = [""];
                allow = false;

                onAdded();
            } else if (editing) {
                onEdited();
            }

            startValue = JSON.stringify({ name, permit, nginx, variables });
        });
    }

    function checkAllow() {
        if (adding) {
            allow =
                name.trim().length > 0 &&
                (permit.filter(a => a.trim().length === 0).length === 0 ||
                    (permit.length === 1 && permit[0].trim().length === 0));
        } else if (editing) {
            allow = startValue !== JSON.stringify({ name, permit, nginx, variables });
        }
    }

    function addPermittedAddress() {
        permit = [...permit, ""];
    }

    function removePermittedAddress(index) {
        index = index ?? permit.length - 1;
        permit = permit.filter((_, i) => i !== index);
    }

    function addVariable() {
        variables[""] = "";
    }

    function removeVariable(name) {
        delete variables[name];
        variables = variables;
    }

    function onVariableNameInput(prevName, newName) {
        if (variables.hasOwnProperty(newName)) {
            return;
        }

        variables[newName] = variables[prevName];
        delete variables[prevName];
    }

    function onVariableValueInput(name, value) {
        variables[name] = value;
    }

    function addressPlaceholder(index) {
        const { length } = addressPlaceholders;
        index = index < length - 1 ? index : index - Math.floor(index / length) * length;
        return addressPlaceholders[index];
    }
</script>

<div class="api-access-form">
    {#if adding || editing}
        <form class="grid-box-1" action="#" method="POST" on:submit={onFormSubmit} disabled={!allow}>
            <h3>
                {#if adding}
                    Adding API access
                {:else}
                    Editing API access
                {/if}

                {#if showHeaderCloseButton}
                    <button class="small" type="button" on:click={() => onHeaderCloseButtonClick(false)}>
                        Close
                    </button>
                {/if}
            </h3>

            <div class="fields">
                <div class="fields-column-left">
                    <div class="grid-box-1">
                        <h4>Access name:</h4>
                        <input type="text" bind:value={name} placeholder="uamobile.net" />
                    </div>

                    <div
                        class="grid-box-1 addresses"
                        class:show-address-number={permit.length > 1}
                        class:hide-button-remove={permit.length < 2}
                    >
                        <div>
                            <h4>Address filtering:</h4>

                            <p>
                                <small>Enter the addresses from which API requests can be received.</small>
                                <br />
                                <small>Leave the fields blank so that requests are accepted from any address.</small>
                            </p>

                            {#each permit as _, index}
                                <div class="address">
                                    <div class="address-number">{index + 1}</div>

                                    <input
                                        type="text"
                                        bind:value={permit[index]}
                                        placeholder={addressPlaceholder(index)}
                                    />

                                    <div class="button-remove-container">
                                        <button
                                            class="small"
                                            type="button"
                                            on:click={removePermittedAddress.bind(null, index)}
                                            disabled={permit.length <= 1}
                                        >
                                            &minus; Remove
                                        </button>
                                    </div>
                                </div>
                            {/each}
                        </div>

                        <div>
                            <button class="small" type="button" on:click={addPermittedAddress}>&plus; Add</button>
                        </div>
                    </div>
                </div>

                <div class="fields-column-right">
                    <div
                        class="grid-box-1"
                        class:show-variable-number={variables.length > 1}
                        class:hide-button-remove={variables.length < 2}
                    >
                        <h4>Variables:</h4>

                        {#each Object.entries(variables) as [name, value], index}
                            <div class="variable">
                                <div class="variable-number">{index + 1}</div>

                                <input
                                    type="text"
                                    value={name}
                                    on:input={({ target: { value } }) => onVariableNameInput(name, value)}
                                    on:change={({ target: { value } }) => onVariableNameInput(name, value)}
                                    placeholder="SERVER_ADDR"
                                />

                                <input
                                    type="text"
                                    {value}
                                    on:input={({ target: { value } }) => onVariableValueInput(name, value)}
                                    on:change={({ target: { value } }) => onVariableValueInput(name, value)}
                                    placeholder="127.0.0.1:8080"
                                />

                                <div class="button-remove-container">
                                    <button class="small" type="button" on:click={removeVariable.bind(null, name)}>
                                        &minus; Remove
                                    </button>
                                </div>
                            </div>
                        {/each}

                        <div>
                            <button class="small" type="button" on:click={addVariable}>&plus; Add</button>
                        </div>
                    </div>

                    <div class="grid-box-1">
                        <h4>Nginx config template:</h4>
                        <textarea class="code nginx" bind:value={nginx} />
                    </div>
                </div>
            </div>

            <div style="justify-self: flex-end;">
                <button type="button" on:click={onFormSubmit} disabled={!allow}>
                    {#if adding}
                        Add
                    {:else if editing}
                        Save
                    {/if}
                </button>

                {#if showFooterCloseButton}
                    <button class="red" type="button" on:click={() => onFooterCloseButtonClick(false)}>Close</button>
                {/if}
            </div>
        </form>
    {/if}
</div>

<style>
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;

        padding: 0;
        box-shadow: none;
    }

    .fields .fields-column-left {
        display: grid;
        grid-template-rows: max-content 1fr;
        grid-gap: 10px;
    }

    .fields .fields-column-right {
        display: grid;
        grid-gap: 10px;
    }
    .fields .fields-column-right > * {
        grid-template-rows: max-content 1fr;
    }

    .addresses {
        align-items: flex-start;
        grid-template-rows: max-content 1fr;
    }

    .address,
    .variable {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-gap: 8px;
        align-items: flex-end;
    }

    .variable {
        grid-template-columns: 200px 1fr max-content;
    }

    .address-number,
    .variable-number {
        align-self: center;
        display: none;
        padding: 0 16px;
        font-size: small;
        text-align: right;
    }

    .show-address-number .address,
    .show-variable-number .variable {
        grid-template-columns: max-content 1fr max-content;
    }

    .show-address-number .address-number,
    .show-variable-number .variable-number {
        display: block;
    }

    .hide-button-remove .address,
    .hide-button-remove .variable {
        grid-gap: 0;
    }

    .hide-button-remove .button-remove-container {
        display: none;
    }

    .nginx {
        min-height: 400px;
    }
</style>
