<script>
    import { useLocation } from "svelte-navigator";
    import ROUTES from "../routes";

    let location = useLocation();
</script>

<div class="containers">
    {#each Object.entries(ROUTES) as [key, route]}
        <div class="container" class:active={key === $location.pathname || route.alias?.includes($location.pathname)}>
            <svelte:component this={route.component} />
        </div>
    {/each}
</div>

<style>
    .containers {
        position: relative;
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;

        opacity: 0;
        pointer-events: none;
        transform: scale(0.9);
        transition: all 500ms ease;
    }

    .container.active {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
    }
</style>
