<script>
    import * as api from "../api";
    import { diff, fmt } from "../../lib/date";

    import APIAccessForm from "./APIAccessForm.svelte";
    import Confirm from "./Confirm.svelte";

    export let refresh = Date.now();
    export let onRefreshStart = () => {};
    export let onRefreshEnd = () => {};

    let domCopyArea;

    let request = false;
    let list = [];
    let filter = JSON.parse(window.sessionStorage.getItem("api-list-filter") ?? "{}");
    $: window.sessionStorage.setItem("api-list-filter", JSON.stringify(filter));

    let editName = JSON.parse(window.sessionStorage.getItem("api-list-editId") ?? "null");
    $: window.sessionStorage.setItem("api-list-editId", JSON.stringify(editName));

    $: request && onRefreshStart();
    $: !request && onRefreshEnd();

    // Confirm's
    let cDeletion = {};
    let cCall = {};

    function load() {
        request = true;
        api.ApiAccessList(filter)
            .then(res => {
                list = (res.list || []).map((item, index) => {
                    item.odd = index % 2 !== 0;

                    item.tokenStart = item.token.length > 16 ? `${item.token.substr(0, 8)}` : null;
                    item.tokenEnd = item.token.length > 16 ? `${item.token.substr(-8)}` : null;

                    return item;
                });

                filter = res?.filter || filter;
            })
            .finally(() => (request = false));
    }

    function onEditClick(name) {
        console.log("name", name);
        editName = editName === name || !name ? null : name;
    }

    function onCopyToken(token) {
        domCopyArea.value = token;
        domCopyArea.select();
        document.execCommand("copy", true, token);
    }

    function onFooterCloseButtonClick() {
        editName = null;
    }

    function hrUse(use = Date.now()) {
        const { D, h, m, s } = diff(use);

        return [
            //
            D > 0 && `${D}d`,
            h > 0 && `${h}h`,
            m > 0 && `${m}m`,
            s > 0 && `${s}s`,
        ]
            .filter(v => v)
            .join(" ");
    }

    function onDeleteClick(required = "") {
        cDeletion = { show: true, required, error: "", value: "" };
    }

    function onDeletionConfirm(value = "") {
        cDeletion = { ...cDeletion, error: null };

        if (!value?.trim()) {
            return;
        } else if (value !== cDeletion.required) {
            cDeletion = { ...cDeletion, error: `Not a match!` };
            return;
        }

        cDeletion = { ...cDeletion, loading: true };

        api.ApiAccessDel(value)
            .then(res => {
                console.warn({ res });
                refresh = Date.now();
                return load();
            })
            .finally(() => {
                cDeletion = { ...cDeletion, show: false, loading: false };
            });
    }

    function onDeletionCancel() {
        cDeletion = { ...cDeletion, show: false };
    }

    function onCallClick(apiName = "") {
        cCall = { show: true, apiName, error: "", value: "" };
    }

    function onCallConfirm(value = "") {
        cCall = { ...cCall, value, error: null };

        if (!value?.trim()) {
            return;
        }

        const domains = value
            .replace(/[\r\n\s]+/g, " ")
            .replace(/\s+/g, " ")
            .split(" ");

        const reTest = domains.filter(domain => !/^(\.?[a-z\d\-_]+)+\.[a-z]+$/.test(domain));
        if (reTest.length > 0) {
            cCall = { ...cCall, error: `Invalid domain: ${reTest.join(", ")}` };
            return;
        }

        if (domains.length === 0) {
            return;
        }

        cCall = { ...cCall, loading: true };

        const apiAccess = list.filter(({ name }) => name === cCall.apiName)[0];

        (async () => {
            for (const domain of domains) {
                console.log("ApiAdd >", domain);

                await api
                    .ApiAdd(domain, apiAccess.token)
                    .then(res => {
                        console.warn({ res });
                        refresh = Date.now();
                        return load();
                    })
                    .finally(() => {
                        cCall = { ...cCall, show: false, loading: false };
                    });
            }
        })();
    }

    function onCallCancel() {
        cCall = { ...cCall, show: false };
    }

    $: refresh && load();
</script>

<textarea class="clipboard" bind:this={domCopyArea} readonly />

<div class="api-list">
    {#if list.length === 0}
        <div class="grid-box-1">
            <p>Empty.</p>
        </div>
    {:else}
        <div class="grid-box-1 list">
            <div class="header" />
            <div class="header">Token</div>
            <div class="header">Permit</div>
            <div class="header">Active</div>
            <div class="header" />

            {#each list as { date, name, token, permit, variables, nginx, active, use, domainCount, odd, tokenStart, tokenEnd }, index}
                <div class="number" class:odd>{index + 1}</div>

                <div class:odd>
                    <p>
                        {name}

                        <br />

                        <small class="info">
                            Add: {fmt(date)}

                            {#if use}
                                <span class="black">|</span> Use: {hrUse(use)}
                            {/if}

                            {#if domainCount}
                                <br />
                                Domains: {domainCount}
                            {/if}
                        </small>
                    </p>

                    <p>
                        <small>
                            <button class="link" type="button" on:click={() => onCopyToken(token)}>COPY</button>

                            {#if tokenStart && tokenEnd}
                                <code>{tokenStart}</code><span>...</span><code>{tokenEnd}</code>
                            {:else}
                                <code>{token}</code>
                            {/if}
                        </small>
                    </p>
                </div>

                <div class="permit" class:odd class:place-center={permit[0] === ""}>
                    {#each permit as permit}
                        <p>{permit === "" ? "ALL" : permit}</p>
                    {/each}
                </div>

                <div class="active" class:odd>
                    <div class="active-status" class:is-active={active} />
                </div>

                <div class="actions" class:odd>
                    <div>
                        <button class="link small" on:click={() => onEditClick(name)} disabled={editName === name}>
                            Edit
                        </button>

                        <button class="link small" on:click={() => onDeleteClick(name)}>Delete</button>

                        <button class="link small" on:click={() => onCallClick(name)}>Call</button>
                    </div>
                </div>

                {#if editName === name}
                    <div class="edit-area">
                        <APIAccessForm editing {name} {permit} {variables} {nginx} {onFooterCloseButtonClick} />
                    </div>
                {/if}
            {/each}
        </div>
    {/if}
</div>

<Confirm
    show={cDeletion.show}
    loading={cDeletion.loading}
    error={cDeletion.error}
    message={cDeletion.required && `Confirm the deletion by entering <b>${cDeletion.required}</b> in the field below:`}
    value={cDeletion.value}
    placeholder={cDeletion.required}
    onConfirm={value => onDeletionConfirm(value)}
    onCancel={() => onDeletionCancel()}
/>

<Confirm
    show={cCall.show}
    loading={cCall.loading}
    error={cCall.error}
    title="Enter domain"
    message={`Enter domain to generate ssl certificate and nginx-config by selected API-access <b>${
        cCall?.apiName || "..."
    }</b>.`}
    value={cCall.value}
    placeholder="example.com"
    onConfirm={value => onCallConfirm(value)}
    onCancel={() => onCallCancel()}
/>

<style>
    .clipboard {
        position: fixed;
        top: -1px;
        left: -1px;
        width: 0;
        height: 0;
        pointer-events: none;
        opacity: 0;
    }

    .list {
        grid-gap: 0;
        grid-template-columns: max-content 1fr max-content max-content max-content;
    }

    .list > * {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .edit-area {
        padding: 20px;
        grid-column: 1 / 6;

        background-color: #f6f6f6;
    }

    .header {
        font-size: larger;
        font-weight: bold;
    }

    .number {
        display: grid;
        place-items: center flex-end;

        padding: 0 16px;
        font-size: small;
    }

    .info {
        color: #666666;
    }

    code {
        word-break: break-all;
    }

    .permit {
        display: grid;
        place-items: flex-start center;
    }

    .permit.place-center {
        place-items: center;
    }

    .active {
        display: grid;
        place-items: center;
    }

    .active-status {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: tomato;
        box-shadow: 0 0 4px #444444;
    }

    .active-status.is-active {
        background-color: yellowgreen;
    }

    .actions {
        display: grid;
        align-items: center;
    }

    .actions > * > * {
        display: block;
    }

    .odd {
        background-color: #f2f2f2;
    }

    .black {
        color: black;
    }
</style>
