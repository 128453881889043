<script>
    export let asLink = false;
    export let show = null;

    export let domain;
    export let job;
    export let cert;

    export let onCertOwnClick;
    export let onCertRenewClick;
    export let onConfRenewClick;
    export let onDeleteClick;

    export let onHideActionsClick = null;
</script>

<div class="actions-root" class:show>
    <div class="actions-buttons">
        <div class="domain">
            <h3>{domain}</h3>
        </div>

        {#if !job?.lock}
            {#if cert?.isWildcard}
                <button class="small" class:link={asLink} on:click={() => onCertOwnClick(domain)}>Cert Own</button>
            {:else}
                <button class="small" class:link={asLink} on:click={() => onCertRenewClick(domain)}>Cert Renew</button>
            {/if}

            <button class="small" class:link={asLink} on:click={() => onConfRenewClick(domain)}>Conf Renew</button>

            <button class="small" class:link={asLink} on:click={() => onDeleteClick(domain)}>Delete</button>
        {/if}

        {#if onHideActionsClick}
            <button class="small red" class:link={asLink} on:click={() => onHideActionsClick(domain)}>Close</button>
        {/if}
    </div>
</div>

<style>
    .domain {
        display: none;
    }

    .actions-buttons {
        display: grid;
    }

    .actions-buttons button {
        text-align: center;
    }

    @media (max-width: 719.95px) {
        .actions-root {
            position: fixed;
            z-index: 1024;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            background-color: rgba(255, 255, 255, 0.85);

            display: grid;
            grid-gap: 20px;
            align-items: center;

            opacity: 0;
            pointer-events: none;

            transition: all 350ms ease;
        }

        .actions-root.show {
            opacity: 1;
            pointer-events: all;
        }

        .domain {
            display: block;
            text-align: center;
            padding: 20px 0;
        }

        .actions-buttons {
            display: grid;
            grid-gap: 4px;
            grid-template-columns: 1fr;
        }

        .actions-buttons button {
            text-align: center;
        }
    }
</style>
