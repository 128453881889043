<script>
    import { requests } from "../store";

    let active = false;

    $: {
        active = Object.values($requests).filter(request => request.__active__).length > 0;
    }
</script>

<div class="request-lock" class:active />
<div class="request" class:active />

<style>
    .request {
        position: fixed;
        z-index: 2048;

        top: 0;
        left: 0;
        width: 100vw;
        height: 8px;
        box-shadow: 0 0 5px #cccccc;

        opacity: 0;
        transition: all 200ms ease;

        animation: request 2000ms ease infinite;
    }

    .request.active {
        opacity: 1;
    }

    .request-lock {
        position: fixed;
        z-index: 2048;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.5);

        opacity: 0;
        pointer-events: none;
        transition: all 200ms ease;
    }

    .request-lock.active {
        opacity: 1;
        pointer-events: all;
    }

    @keyframes request {
        0% {
            background-color: yellowgreen;
        }

        25% {
            background-color: coral;
        }

        50% {
            background-color: cornflowerblue;
        }

        75% {
            background-color: lightcoral;
        }

        100% {
            background-color: yellowgreen;
        }
    }
</style>
