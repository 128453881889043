<script>
    import { token } from "./store";

    import Styles from "./components/Styles.svelte";
    import RequestBar from "./components/RequestBar.svelte";
    import Socket from "./components/Socket.svelte";
    import Login from "./components/Login.svelte";
    import Router from "./components/Router.svelte";
</script>

<Styles />
<RequestBar />
<Socket />

{#if !$token}
    <Login />
{:else}
    <Router />
{/if}
