<script>
    import { onMount } from "svelte";
    import * as api from "../api";
    import { fmt } from "../../lib/date";

    let list = [];

    onMount(() => load());

    function load() {
        api.AccountList().then(res => {
            if (!res?.list) {
                console.warn("BAD", res);
                return;
            }

            list = res.list.map((account, index) => {
                account.odd = index % 2 !== 0;
                // ! TODO
                // console.log(account);
                return account;
            });
        });
    }
</script>

<div class="grid-box-1">
    <div class="list">
        <!-- Header -->
        <div class="header">Add</div>
        <div class="header">Login</div>
        <div class="header">Tokens</div>
        <div class="header">Active</div>
        <div class="header" />

        <!-- List -->
        {#if list.length > 0}
            {#each list as { date, login, tokens, active, odd }}
                <div class:odd>{fmt(date)}</div>
                <div class:odd>{login}</div>
                <div class:odd>{tokens.length}</div>
                <div class:odd>{active ? "Yes" : "No"}</div>

                <div class="actions" class:odd>
                    <button class="link small" type="button">Edit</button>
                    <button class="link small" type="button">Delete</button>
                </div>
            {/each}
        {/if}
    </div>
</div>

<style>
    .list {
        display: grid;
        grid-template-columns: repeat(1, max-content) 1fr repeat(3, max-content);

        padding: 20px;
        box-shadow: 0 0 5px #cccccc;
    }

    .list > * {
        display: grid;
        align-items: center;
        padding: 8px 24px;
    }

    .header {
        font-size: larger;
        font-weight: bold;
    }

    .odd {
        background-color: #f2f2f2;
    }

    .actions {
        justify-items: flex-start;
    }
</style>
