import axios from "axios";

import * as store from "../store";

let token; // { "TokenHeaderName": "abc.123..." }
store.token.subscribe(v => {
    token = v ? { [v.name]: v.value } : null;
});

function post(url, data, headers = {}) {
    const id = addRequest({ url, data });
    return axios
        .post(url, data, { headers: { ...headers } })
        .then(({ data }) => data)
        .finally(() => delRequest(id));
}

function postSigned(url, data) {
    const id = addRequest({ url, data });
    return axios
        .post(url, { ...data }, { headers: { ...token } })
        .then(({ data }) => {
            if (data?.e === "UNAUTHORIZED") {
                store.token.update(v => null);
                return null;
            }

            return data;
        })
        .finally(() => delRequest(id));
}

// <RequestBar /> ----------------------------------------------------------- //
function addRequest(params) {
    const p1 = (() => {
        const min = 1000000000;
        const max = 9999999999;

        return Math.round(Math.random() * (max - min) + min).toString(16);
    })();

    const p2 = Date.now().toString(16);

    const id = `${p1}-${p2}`;

    store.requests.update(value => {
        value = { ...value, [id]: { id, ...params, __active__: true } };
        return value;
    });

    return id;
}

function delRequest(id) {
    store.requests.update(value => {
        for (const idRequest in value) {
            if (idRequest === id) {
                value[idRequest].__active__ = false;
            }
        }

        return value;
    });
}

function rp(route = "") {
    return `/client${route}`;
}
// -------------------------------------------------------------------------- //

export function ApiAdd(domain = "", xApiToken = "") {
    return post("/api/add", { domain }, { "x-api-token": xApiToken });
}

export function AccountAdd() {
    return postSigned(rp("/account-add"));
}

export function AccountList() {
    return postSigned(rp("/account-list"));
}

export function AccountUpd() {
    return postSigned(rp("/account-upd"));
}

export function ApiAccessAdd(name = "", permit = [], nginx = "", variables = {}) {
    return postSigned(rp("/api-access-add"), { name, permit, nginx, variables });
}

export function ApiAccessDel(name = "") {
    return postSigned(rp("/api-access-del"), { name });
}

export function ApiAccessList(filter) {
    return postSigned(rp("/api-access-list"), { filter });
}

export function ApiAccessUpd(name = "", permit = [], nginx = "", variables = {}) {
    return postSigned(rp("/api-access-upd"), { name, permit, nginx, variables });
}

export function CertificateList() {
    return postSigned(rp("/certificates-list"), {});
}

export function DomainAdd(domain = "") {
    return postSigned(rp("/domain-add"), { domain });
}

export function DomainCertOwn(domain = "") {
    return postSigned(rp("/domain-own"), { domain });
}

export function DomainCertRenew(domain = "") {
    return postSigned(rp("/domain-cert-renew"), { domain });
}

export function DomainConfRenew(domain = "") {
    return postSigned(rp("/domain-conf-renew"), { domain });
}

export function DomainDel(domain = "") {
    return postSigned(rp("/domain-del"), { domain });
}

export function DomainList(filter = {}) {
    return postSigned(rp("/domain-list"), { filter });
}

export function Login(data = { login: "", password: "" }) {
    return post(rp("/login"), data);
}

export function Logout() {
    return postSigned(rp("/logout"));
}
