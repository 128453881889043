import { readable, writable } from "svelte/store";

import IO from "socket.io-client";
import { listRefresh } from ".";

const socket = IO();
socket.on("connect", () => {
    // console.log("[socket] connected.");
});

socket.on("disconnect", () => {
    // console.log("[socket] disconnected.");
});

socket.on("change", change => {
    if (change.col === "domains") {
        listRefresh.update(value => ({ ...value, domains: Date.now() }));
        return;
    }

    console.log("change", change);
});

export let Socket = readable(socket);
