const msSecond = 1000;
const msMinute = msSecond * 60;
const msHour = msMinute * 60;
const msDay = msHour * 24;

function fmt(date = new Date(), format = "Y-M-D h:m:s") {
    date = new Date(date);

    const [YY, Y, M, D, h, m, s, ms] = [
        date.getFullYear().toString().slice(2),
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
        date.getHours().toString().padStart(2, "0"),
        date.getMinutes().toString().padStart(2, "0"),
        date.getSeconds().toString().padStart(2, "0"),
        date.getMilliseconds().toString().padEnd(3, "0"),
    ];

    return format
        .replace(/ms/g, ms)
        .replace(/YY/g, YY)
        .replace(/Y/g, Y)
        .replace(/M/g, M)
        .replace(/D/g, D)
        .replace(/h/g, h)
        .replace(/m/g, m)
        .replace(/s/g, s);
}

function diff(prevDate = new Date(), currentDate = new Date()) {
    let D,
        h,
        m,
        s,
        ms = currentDate - prevDate;

    ms -= (D = Math.floor(ms / msDay)) * msDay;
    ms -= (h = Math.floor(ms / msHour)) * msHour;
    ms -= (m = Math.floor(ms / msMinute)) * msMinute;
    ms -= (s = Math.floor(ms / msSecond)) * msSecond;

    return { D, h, m, s, ms };
}

if (typeof module !== "undefined" && module.exports) {
    module.exports = { fmt, diff };
}

if (typeof define === "function" && define.amd) {
    define([], function () {
        return { fmt, diff };
    });
}
