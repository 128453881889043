import { writable } from "svelte/store";

export let token = writable(JSON.parse(window.sessionStorage.getItem("token") ?? "null"));
token.subscribe(value => {
    if (!value) {
        window.sessionStorage.removeItem("token");
    } else {
        window.sessionStorage.setItem("token", JSON.stringify(value));
    }
});

export let requests = writable({});

export let listRefresh = writable({ domains: Date.now() });
