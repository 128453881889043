<script>
    import * as api from "../api";
    import { fmt, diff } from "../../lib/date";
    import ArrowUpDown from "./ArrowUpDown.svelte";

    let list = [];

    let filter = {
        sort: "expire",
        asc: false,
    };

    $: filter && load();

    function load() {
        api.CertificateList().then(res => {
            list = res.list
                .sort((a, b) => {
                    a = a[filter.sort];
                    b = b[filter.sort];
                    return a === b ? 0 : (filter.asc ? a > b : b < a) ? 1 : -1;
                })
                .map((cert, index) => {
                    cert.number = index + 1;
                    cert.odd = index % 2 !== 0;
                    cert.left = hrExpire(cert.expire);
                    return cert;
                });
        });
    }

    function sort(field) {
        let sort = field;
        let asc = filter.asc;

        if (filter.sort === sort) {
            if (filter.asc) {
                asc = false;
            } else {
                sort = "name";
                asc = true;
            }

            filter = { ...filter, asc };
        }

        filter = { ...filter, sort, asc };
    }

    function hrExpire(expire = Date.now()) {
        const expired = Date.now() > expire;

        const _args = expired ? [expire, Date.now()] : [Date.now(), expire];
        const { D, h, m, s, ms } = diff(..._args);

        const showSeconds = true; // expire - Date.now() < ExpiresWarning;

        return [
            //
            expired ? "Expired" : "",

            D > 0 && `${D}d`,
            D + h > 0 && `${h}h`,
            D + h + m > 0 && `${m}m`,
            (D + h === 0 || showSeconds) && `${s}s`,

            expired ? "ago" : "",
        ]
            .filter(v => v)
            .join(" ");
    }
</script>

<div class="grid-box-1">
    <div class="list">
        <div class="header" />

        <div>
            <button class="header link" on:click={() => sort("date")}>
                Date<ArrowUpDown show={filter.sort === "date"} up={filter.asc} />
            </button>
        </div>

        <div>
            <button class="header link" on:click={() => sort("name")}>
                Name<ArrowUpDown show={filter.sort === "name"} up={filter.asc} />
            </button>
        </div>

        <div>
            <button class="header link" on:click={() => sort("dns")}>
                DNS<ArrowUpDown show={filter.sort === "dns"} up={filter.asc} />
            </button>
        </div>

        <div>
            <button class="header link" on:click={() => sort("expire")}>
                Expire<ArrowUpDown show={filter.sort === "expire"} up={filter.asc} />
            </button>
        </div>

        {#each list as { date, name, expire, left, dns, number, odd }}
            <div class:odd>
                <small>{number}</small>
            </div>

            <div class:odd>{fmt(date)}</div>
            <div class:odd>{name}</div>
            <div class:odd>{dns.join(", ")}</div>

            <div class="expire" class:odd>
                <p>{fmt(expire)}</p>

                <p>
                    <small>{left}</small>
                </p>
            </div>
        {/each}
    </div>
</div>

<style>
    .list {
        display: grid;
        grid-template-columns: repeat(3, max-content) 1fr repeat(1, max-content);

        padding: 20px;
        box-shadow: 0 0 5px #cccccc;
    }

    .list > * {
        display: grid;
        align-items: center;
        padding: 8px 24px;
    }

    .header {
        font-size: larger;
        font-weight: bold;
    }

    .header.link {
        text-align: left;
    }

    .expire {
        text-align: center;
    }

    .odd {
        background-color: #f2f2f2;
    }
</style>
