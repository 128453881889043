<script>
    // import { DomainAdd } from "../api";

    let list = [
        {
            name: "LISTEN_443",
            value: `
listen 443 ssl;
ssl_certificate /etc/letsencrypt/live/VAR:SSL_FULLCHAIN/fullchain.pem;
ssl_certificate_key /etc/letsencrypt/live/VAR:SSL_PRIVKEY/privkey.pem;
include /etc/letsencrypt/options-ssl-nginx.conf;
ssl_dhparam /etc/letsencrypt/ssl-dhparams.pem;
`.trim(),
        },

        {
            name: "PROXY_MOD",
            value: `
proxy_redirect off;
proxy_set_header Host $host;
proxy_set_header X-Real-IP $remote_addr;
proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
proxy_set_header X-Forwarded-Proto $scheme;
proxy_set_header X-Original-Uri $request_uri;

proxy_set_header Upgrade $http_upgrade;
proxy_set_header Connection "upgrade";
proxy_cache_bypass $http_upgrade;
`.trim(),
        },

        {
            name: "GZIP",
            value: `
gzip on;
gzip_comp_level 9;
gzip_min_length 1000;
gzip_proxied expired no-cache no-store private auth;
gzip_types *;
`.trim(),
        },
    ];

    // DomainAdd
</script>

<div class="container">
    <div class="grid-box-1 actions-row">
        <button type="button">Button</button>
    </div>

    <div class="grid-box-1">
        <h3>NGINX Configs</h3>

        <div class="list">
            {#each list as conf}
                <div>
                    <input class="code" type="text" bind:value={conf.name} />
                    <textarea class="code" bind:value={conf.value} rows={(conf.value.match(/\n/g)?.length || 0) + 1} />
                </div>
            {/each}
        </div>
    </div>

    <div class="grid-box-1">NGINX Templates</div>
</div>

<style>
    .container {
        display: grid;
        grid-gap: 10px;
    }
</style>
