// Containers
import Dashboard from "./components/Dashboard.svelte";
import API from "./components/API.svelte";
import NGINX from "./components/NGINX.svelte";
import Certificates from "./components/Certificates.svelte";
import Accounts from "./components/Accounts.svelte";

// Routes
const ROUTES = {
    "/dashboard": {
        title: "DASHBOARD",
        bgColor: "dodgerblue",
        component: Dashboard,
        alias: ["/"],
    },

    "/api": {
        title: "API",
        bgColor: "palevioletred",
        component: API,
    },

    "/nginx": {
        title: "NGINX",
        bgColor: "darkolivegreen",
        component: NGINX,
    },

    "/certificates": {
        title: "CERTIFICATES",
        bgColor: "mediumpurple",
        component: Certificates,
    },

    "/accounts": {
        title: "ACCOUNTS",
        bgColor: "#333333",
        component: Accounts,
    },
};

export default ROUTES;
