<script>
    import { Router } from "svelte-navigator";

    import Menu from "./Menu.svelte";
    import Containers from "./Containers.svelte";
</script>

<Router>
    <div class="router">
        <Menu />
        <Containers />
    </div>
</Router>

<style>
    .router {
        height: 100%;
        display: grid;
        grid-template-rows: max-content 1fr;
    }
</style>
