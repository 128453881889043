<script>
    import { onMount } from "svelte";
    import { fmt, diff } from "../../../lib/date";

    import DomainJobStages from "./JobStages.svelte";
    import Actions from "./Actions.svelte";

    export let item;

    export let ExpiresWarning;
    export let ExpiresImportant;
    export let expires;
    export let onCertOwnClick;
    export let onCertRenewClick;
    export let onConfRenewClick;
    export let onDeleteClick;
    export let onShowActionsClick;

    const { odd, date, domain, job, cert, http, https } = item;

    const isPhone = window.matchMedia("(max-width: 719.95px)").matches;

    let asExpire = true;

    let iObserver = new IntersectionObserver(intersection);
    let domExpireDesktop;
    let domExpirePhone;
    let isExpireVisible = false;
    let expireRendering = null;
    let expire = { type: "..." };

    $: {
        if (!isExpireVisible || !asExpire) {
            expireRendering && clearInterval(expireRendering);
        } else if (isExpireVisible) {
            renderExpire();
            expireRendering = setInterval(renderExpire, 500);
        }
    }

    onMount(() => {
        renderExpire();

        domExpireDesktop && iObserver.observe(domExpireDesktop);
        domExpirePhone && iObserver.observe(domExpirePhone);
    });

    function onExpireClick() {
        asExpire = !asExpire;
    }

    function intersection(entries) {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                isExpireVisible = true;
                return;
            }
        }

        isExpireVisible = false;
    }

    function renderExpire() {
        if (!expires || !expires[domain]) {
            return;
        }

        const { type, value } = expires[domain];

        if (type === "No" || isNaN(value)) {
            return;
        }

        expire.value = value;

        if (value < Date.now()) {
            expire.type = "Expired";
        } else {
            expire.type = hrExpire(value);
        }

        function hrExpire(expire = Date.now()) {
            const { D, h, m, s, ms } = diff(Date.now(), expire);

            // const showSeconds = expire - Date.now() < ExpiresWarning;

            return [
                //
                D > 0 && `${D}d`,
                D + h > 0 && `${h}h`,
                D + h + m > 0 && `${m}m`,
                // (D + h === 0 || showSeconds) && `${s}s`,
                D + h + m + s > 0 && `${s}s`,
            ]
                .filter(v => v)
                .join(" ");
        }
    }
</script>

{#if !isPhone}
    <!-- DESKTOP ----------------------------------------------------------- -->

    <div class:odd>
        <div class="date">{fmt(date)}</div>
    </div>

    <div class:odd>
        <div class="link">
            <a href={https || http} target="_blank">{domain}</a>
        </div>
    </div>

    <div class="job" class:odd>
        <DomainJobStages {job} />
    </div>

    {#if job.jobMessage?.length > 0}
        <div class:odd>
            {#each job.jobMessage as msg}
                <p>
                    <small>{msg}</small>
                </p>
            {/each}
        </div>
    {:else}
        <div class="links" class:odd>
            <span class="domain-80">
                <a href={http} target="_blank">HTTP</a>
            </span>

            <span class="domain-443">
                {#if https}
                    <a href={https} target="_blank">HTTPS</a>

                    {#if cert?.isWildcard}
                        (wildcard)
                    {/if}
                {/if}
            </span>
        </div>
    {/if}

    <div
        bind:this={domExpireDesktop}
        class:odd
        class:expires-warning={cert?.expire && cert.expire - Date.now() < ExpiresWarning}
        class:expires-important={cert?.expire && cert.expire - Date.now() < ExpiresImportant}
    >
        <div class="cert">
            {#if expire?.type}
                {#if expire.value >= Date.now()}
                    <p>{fmt(expire?.value)}</p>

                    <p>
                        <small>{expire.type}</small>
                    </p>
                {:else}
                    <p>
                        {expire.type}
                    </p>
                {/if}
            {/if}
        </div>
    </div>

    <Actions
        asLink={true}
        show={true}
        {domain}
        {job}
        {cert}
        {onCertOwnClick}
        {onCertRenewClick}
        {onConfRenewClick}
        {onDeleteClick}
    />
{:else}
    <!-- PHONE ------------------------------------------------------------- -->

    <div class="phone" class:odd>
        <div>
            <div class="link">
                <!-- <DomainJobStages {job} /> -->

                <a href={https || http} target="_blank">{domain}</a>

                {#if cert?.isWildcard}
                    [WC]
                {/if}
            </div>
        </div>

        <div
            bind:this={domExpirePhone}
            class:expires-warning={cert?.expire && cert.expire - Date.now() < ExpiresWarning}
            class:expires-important={cert?.expire && cert.expire - Date.now() < ExpiresImportant}
        >
            <div class="cert">
                {#if expire?.type}
                    {#if expire.value >= Date.now()}
                        {#if asExpire}
                            Expires in:
                            <button class="link small" on:click={onExpireClick}>
                                {expire.type}
                            </button>
                        {:else}
                            Expire:
                            <button class="link small" on:click={onExpireClick}>
                                {fmt(expire?.value)}
                            </button>
                        {/if}
                    {:else}
                        <p>
                            {expire.type}
                        </p>
                    {/if}
                {/if}
            </div>
        </div>

        {#if job.jobMessage?.length > 0}
            <div class:odd>
                {#each job.jobMessage as msg}
                    <p>
                        <small>{msg}</small>
                    </p>
                {/each}
            </div>
        {/if}

        {#if onShowActionsClick}
            <button class="link small" on:click={() => onShowActionsClick(domain)}>Actions</button>
        {/if}

        <!-- Actions from ./List.svelte -->

        <div>
            <div class="date">{fmt(date)}</div>
        </div>
    </div>
{/if}

<style>
    .odd {
        background-color: #f2f2f2;
    }

    .expires-warning {
        background-color: orange;
        color: white;
    }

    .expires-important {
        background-color: tomato;
    }

    .job {
        justify-items: center;
    }

    .links {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 10px;
        justify-content: flex-start;
    }

    .cert {
        text-align: center;
    }

    /* Phone ---------------------------------------------------------------- */
    .phone {
        display: grid;
        grid-gap: 8px;
    }

    .phone .link {
        font-size: 24px;
    }

    .phone .date {
        text-align: right;
        font-size: smaller;
        color: #999999;
    }

    .phone .cert {
        text-align: left;
    }
</style>
