<script>
    import { onDestroy, onMount } from "svelte";

    import * as api from "../../api";
    import { fmt } from "../../../lib/date";
    import { Socket } from "../../store/socket";
    import { listRefresh } from "../../store";

    import ArrowUpDown from "../ArrowUpDown.svelte";
    import RefreshTime from "../RefreshTime.svelte";
    import Confirm from "../Confirm.svelte";
    import DomainListItem from "./Item.svelte";
    import Actions from "./Actions.svelte";

    const isPhone = window.matchMedia("(max-width: 719.95px)").matches;

    const ExpiresWarning = 1000 * 60 * 60 * 24 * 7;
    const ExpiresImportant = 1000 * 60 * 60 * 24 * 2;

    const DefaultFilter = { sort: "cert", asc: true, limit: -1, cert: null };

    let request = true;
    let lastLoad = null;
    let filter = JSON.parse(window.sessionStorage.getItem("domain-list-filter") || "null") || { ...DefaultFilter };
    $: window.sessionStorage.setItem("domain-list-filter", JSON.stringify(filter));

    let total = null;
    let list = [];
    let expires = {};

    // Confirm's
    let cCertRenew = {};
    let cConfRenew = {};
    let cDeletion = {};

    // Actions
    let domActions;

    let aShow = {
        show: false,
        domain: null,
        job: null,
        cert: null,
    };

    onMount(() => {
        domActions && document.body.appendChild(domActions);
    });

    onDestroy(() => {
        domActions && domActions.parentElement.removeChild(domActions);
    });

    onMount(() => {
        $Socket.on("update-domains", onSocketUpdateDomains);
    });

    onDestroy(() => {
        $Socket.off("update-domains", onSocketUpdateDomains);
    });

    function onSocketUpdateDomains(data) {
        load();
    }

    // Uncategorized
    function load() {
        list = [];
        expires = {};

        request = true;
        lastLoad = null;

        api.DomainList(filter)
            .then(res => {
                lastLoad = Date.now();

                list = (res.list || []).map((item, index) => {
                    item.odd = index % 2 !== 0;
                    item.http = `http://${item.domain}`;
                    item.https = item?.cert?.expire > Date.now() ? `https://${item.domain}` : null;

                    item.job.jobMessage = [];

                    item.job.delete === true && item.job.jobMessage.push(`DELETING`);
                    item.job.own === true && item.job.jobMessage.push(`OWNING`);
                    item.job.certRenew === true && item.job.jobMessage.push(`CERT RENEWING`);
                    item.job.confRenew === true && item.job.jobMessage.push(`CONF RENEWING`);

                    item.job.okDig === false && item.job.jobMessage.push(`LOOKUP`);
                    item.job.okCert === false && item.job.jobMessage.push(`CERTIFICATE`);
                    item.job.okTpl === false && item.job.jobMessage.push(`TEMPLATE`);
                    item.job.okConf === false && item.job.jobMessage.push(`CONFIG`);

                    item.job.certErrorCode &&
                        item.job.jobMessage.push(
                            (() => {
                                if (item.job.certErrorCode === "TOO_MANY_CERTIFICATES_ALREADY_ISSUED") {
                                    return `${item.job.certErrorCode}: RETRY ${fmt(item.job.certErrorData.retry)}`;
                                }

                                return item.job.certErrorCode;
                            })()
                        );

                    // item.job.jobMessage = item.job.jobMessage.join(", ");

                    if (item?.cert?.expire && item.cert.expire >= 0) {
                        expires[item.domain] = {
                            type: null,
                            value: item.cert.expire,
                        };
                    } else {
                        expires[item.domain] = {
                            type: "No",
                            value: null,
                        };
                    }

                    return item;
                });

                total = res.total;

                filter = res?.filter || filter;
            })
            .catch(e => {
                setTimeout(() => load(), 1000);
            })
            .finally(() => {
                request = false;
            });
    }

    function sort(field = "") {
        if (filter.sort !== field) {
            filter.sort = field;
            filter.asc = true;
        } else {
            filter.asc = !filter.asc;
        }

        filter = filter;

        load();
    }

    // Events
    function onSearchInput() {
        filter = { ...filter, search: this.value };
    }

    function onSearchKeyUp(event) {
        if (event.key !== "Enter" || event.keyCode !== 13) {
            return;
        }

        filter = { ...filter, search: this.value };
        load();
    }

    function onCertOwnClick(domain = "") {
        api.DomainCertOwn(domain).then(() => load());
    }

    function onCertRenewClick(required = "") {
        cCertRenew = { show: true, required, error: "", value: "" };
    }

    function onCertRenewingConfirm(value = "") {
        cCertRenew = { ...cCertRenew, error: null };

        if (!value?.trim()) {
            return;
        } else if (value !== cCertRenew.required) {
            cCertRenew = { ...cCertRenew, error: `Not a match!` };
            return;
        }

        cCertRenew = { ...cCertRenew, loading: true };
        aShow = { ...aShow, show: false };

        api.DomainCertRenew(value)
            .then(res => load())
            .finally(() => {
                cCertRenew = { ...cCertRenew, show: false, loading: false };
            });
    }

    function onCertRenewingCancel() {
        cCertRenew = { ...cCertRenew, show: false };
    }

    function onConfRenewClick(required = "") {
        cConfRenew = { show: true, required, error: "", value: "" };
    }

    function onConfRenewingConfirm(value = "") {
        cConfRenew = { ...cConfRenew, error: null };

        if (!value?.trim()) {
            return;
        } else if (value !== cConfRenew.required) {
            cConfRenew = { ...cConfRenew, error: `Not a match!` };
            return;
        }

        cConfRenew = { ...cConfRenew, loading: true };
        aShow = { ...aShow, show: false };

        api.DomainConfRenew(value)
            .then(res => load())
            .finally(() => {
                cConfRenew = { ...cConfRenew, show: false, loading: false };
            });
    }

    function onConfRenewingCancel() {
        cConfRenew = { ...cConfRenew, show: false };
    }

    function onDeleteClick(required = "") {
        cDeletion = { show: true, required, error: "", value: "" };
    }

    function onDeletionConfirm(value = "") {
        cDeletion = { ...cDeletion, error: null };

        if (!value?.trim()) {
            return;
        } else if (value !== cDeletion.required) {
            cDeletion = { ...cDeletion, error: `Not a match!` };
            return;
        }

        cDeletion = { ...cDeletion, loading: true };
        aShow = { ...aShow, show: false };

        api.DomainDel(value)
            .then(res => {
                console.warn({ res });
                return load();
            })
            .finally(() => {
                cDeletion = { ...cDeletion, show: false, loading: false };
            });
    }

    function onDeletionCancel() {
        cDeletion = { ...cDeletion, show: false };
    }

    function onShowActionsClick(domain) {
        const { job, cert } = list.filter(item => item.domain === domain)[0];

        aShow = {
            show: true,
            domain,
            job,
            cert,
        };
    }

    function onHideActionsClick() {
        aShow = { ...aShow, show: false };
    }

    $: $listRefresh.domains && load();
</script>

<div class="desktop-onlyX grid-box-1 actions-row actions clean">
    <button on:click={() => load()} disabled={request}>
        Refresh

        {#if request}
            (...)
        {:else}
            (<RefreshTime from={lastLoad} placeholder="..." />)
        {/if}
    </button>
</div>

<div class="list">
    <!-- Header -->
    <div class="desktop-only">
        <button class="header link" on:click={() => sort("date")}>
            Add<ArrowUpDown show={filter.sort === "date"} up={filter.asc} />
        </button>
    </div>

    <div class="desktop-only">
        <button class="header link" on:click={() => sort("domain")}>
            Domain<ArrowUpDown show={filter.sort === "domain"} up={filter.asc} />
        </button>
    </div>

    <div class="desktop-only">
        <button class="header link job" on:click={() => sort("job")}>
            Job<ArrowUpDown show={filter.sort === "job"} up={filter.asc} />
        </button>
    </div>

    <div class="desktop-only header" />

    <div class="desktop-only">
        <button class="header link" on:click={() => sort("cert")}>
            Expire<ArrowUpDown show={filter.sort === "cert"} up={filter.asc} />
        </button>
    </div>

    <div class="desktop-only header" />

    <!-- Search -->
    <div class="desktop-onlyX search full-row">
        <input
            type="search"
            on:input={onSearchInput}
            on:keyup={onSearchKeyUp}
            value={filter.search || ""}
            placeholder="example.com"
            disabled={request}
        />
    </div>

    <!-- Pagination -->
    <div class="desktop-onlyX pagination full-row">
        Total: {total ?? "..."}
    </div>

    <!-- List -->
    {#if list.length > 0}
        {#each list as item (item.domain)}
            <DomainListItem
                {item}
                {...{
                    ExpiresWarning,
                    ExpiresImportant,
                    expires,
                    onCertOwnClick,
                    onCertRenewClick,
                    onConfRenewClick,
                    onDeleteClick,
                    onShowActionsClick,
                }}
            />
        {/each}
    {:else}
        <div class="full-row">
            {#if request}
                Loading...
            {:else}
                Empty.
            {/if}
        </div>
    {/if}
</div>

<Confirm
    show={cCertRenew.show}
    loading={cCertRenew.loading}
    error={cCertRenew.error}
    message={cCertRenew.required &&
        `Confirm the certificate renew by entering <b>${cCertRenew.required}</b> in the field below:`}
    value={cCertRenew.value}
    placeholder={cCertRenew.required}
    onConfirm={value => onCertRenewingConfirm(value)}
    onCancel={() => onCertRenewingCancel()}
/>

<Confirm
    show={cConfRenew.show}
    loading={cConfRenew.loading}
    error={cConfRenew.error}
    message={cConfRenew.required &&
        `Confirm the config renew by entering <b>${cConfRenew.required}</b> in the field below:`}
    value={cConfRenew.value}
    placeholder={cConfRenew.required}
    onConfirm={value => onConfRenewingConfirm(value)}
    onCancel={() => onConfRenewingCancel()}
/>

<Confirm
    show={cDeletion.show}
    loading={cDeletion.loading}
    error={cDeletion.error}
    message={cDeletion.required && `Confirm the deletion by entering <b>${cDeletion.required}</b> in the field below:`}
    value={cDeletion.value}
    placeholder={cDeletion.required}
    onConfirm={value => onDeletionConfirm(value)}
    onCancel={() => onDeletionCancel()}
/>

{#if isPhone}
    <div bind:this={domActions}>
        <Actions
            show={aShow.show}
            {...aShow}
            {onCertOwnClick}
            {onCertRenewClick}
            {onConfRenewClick}
            {onDeleteClick}
            {onHideActionsClick}
        />
    </div>
{/if}

<style>
    .actions,
    .list {
        padding: 20px;
        box-shadow: 0 0 5px #cccccc;
    }

    .list {
        display: grid;
        grid-template-columns: repeat(3, max-content) 1fr repeat(2, max-content);
    }

    .full-row {
        grid-column: 1 / 7;
    }

    .list > :global(*) {
        display: grid;
        align-items: center;
        padding: 8px 32px;
    }

    .list :global(button) {
        text-align: left;
    }

    .header {
        font-size: larger;
        font-weight: bold;
    }

    @media (max-width: 719.95px) {
        .list {
            display: grid;
            grid-template-columns: 1fr;
        }

        .full-row {
            grid-column: unset;
        }

        .list > :global(*) {
            padding: 16px;
            border-radius: 8px;
        }
    }
</style>
