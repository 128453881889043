<script>
    import DomainList from "./DomainList/List.svelte";
</script>

<div class="container dashboard">
    <DomainList />
</div>

<style>
    .dashboard {
        display: grid;
        grid-gap: 10px;
    }
</style>
