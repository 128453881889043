<script>
    import { onMount } from "svelte";

    export let show = false;
    export let loading = false;
    export let title = "";
    export let message = "";
    export let error = "";
    export let value = "";
    export let placeholder = "";
    export let onConfirm = value => {};
    export let onCancel = () => {};

    let dom;

    onMount(() => {
        if (dom.parentElement !== document.body) {
            document.body.appendChild(dom);
        }
    });

    function onSubmit(event) {
        event.preventDefault();

        value.trim() && onConfirm(value);
    }
</script>

<div bind:this={dom} class="confirm-wrap" class:show class:loading>
    <form action="#" class="confirm" on:submit={onSubmit}>
        {#if title}
            <h3 class="title">
                {title}
            </h3>
        {/if}

        {#if message}
            <div class="message">
                {@html message}
            </div>
        {/if}

        <div>
            <div class="input">
                <input type="text" bind:value {placeholder} />
            </div>

            {#if error}
                <div class="error">
                    {error}
                </div>
            {/if}
        </div>

        <div class="confirm-actions">
            <button class="small" type="button" on:click={() => onConfirm(value)} disabled={!(value || "").trim()}>
                Confirm
            </button>

            <button class="small red" type="button" on:click={() => onCancel()}>Cancel</button>
        </div>
    </form>
</div>

<style>
    .confirm-wrap {
        position: fixed;
        z-index: 2048;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.85);

        display: grid;
        place-content: center;

        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease;
    }

    .confirm {
        width: 600px;

        display: grid;

        background-color: #ffffff;
        box-shadow: 0 0 16px #999999;

        opacity: 0;
        transform: translateY(-100px);
        transition: all 300ms ease;
    }

    .confirm > * {
        padding: 10px;
    }

    .title {
        background-color: dodgerblue;
        color: white;
    }

    .error {
        padding: 2px 10px;
        font-size: small;
        color: tomato;
    }

    .confirm-actions {
        justify-self: flex-end;
    }

    .confirm-wrap.show {
        opacity: 1;
        pointer-events: all;
    }

    .confirm-wrap.show .confirm {
        opacity: 1;
        transform: translateY(0);
    }

    .confirm-wrap.loading {
        cursor: wait;
    }

    .confirm-wrap.loading > * {
        filter: grayscale(1) brightness(0.9);
    }

    .confirm-wrap.loading * {
        pointer-events: none;
    }

    @media (max-width: 719.95px) {
        .confirm {
            width: calc(100vw - 20px);
        }
    }
</style>
